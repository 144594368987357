<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('expenseCategory', 1) })"
    :value="value"
    :field-value.sync="expenseCategory.name"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      expenseCategory: {
        name: ''
      },
      edit: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.expenseCategory =
            this.toEdit !== null ? { ...this.toEdit } : { name: '' }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          let category = !this.edit
            ? await this.$api.expense.category.create({
                form: this.expenseCategory
              })
            : await this.$api.expense.category.edit({
                pk: this.expenseCategory.pk,
                form: this.expenseCategory
              })
          this.$emit('done', category.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('expenseCategory', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
